//const BASE_URL = 'http://localhost';
//const BASE_URL = 'http://130.61.202.0';
const BASE_URL = 'https://api.fve.firmisimo.eu';

const PORT = 443;

/* API URLs */
export const API_URL = `${BASE_URL}:${PORT}/api`;
export const API_AUTH_URL = `${API_URL}/auth/`;
export const API_PROJECT_URL = `${API_URL}/projects`;
export const API_COMPONENT_URL = `${API_URL}/components`;
